.page-node-26 h1.page-header {
    display: none;
    visibility: hidden;
}

#about-page {
  .top-row {
    padding: 0;
    margin-bottom: 40px;
  }

  .view-roles {
    padding: 40px 0;

    .views-field-name {
      margin-bottom: 30px;
    }
    h1,h2,h3 {
      line-height: 1.1;
      color: #fff;
      @media (min-width: $screen-sm-min) {line-height: .9 } //768px & greater
    }
    h1 {
      font-size: 15vw;

      @media (min-width: $screen-sm-min) {font-size: 110px } //768px & greater
      @media (min-width: $screen-lg-min) {font-size: 150px} //1200px & greater
    }
    h2 {
      font-size: 10vw;

      @media (min-width: $screen-sm-min) {font-size: 80px } //768px & greater
      @media (min-width: $screen-lg-min) {font-size: 100px} //1200px & greater
    }
    h3 {
      font-size: 7.5vw;

      @media (min-width: $screen-sm-min) {font-size: 68px } //768px & greater
      @media (min-width: $screen-lg-min) {font-size: 85px} //1200px & greater
    }
    .slick-slide {
      .jumbotron-role {
        display: inline-block;
        position: relative;
        text-transform: lowercase;
        @include transform-origin(left bottom)
      }
    }
    .slick-slide.slick-active {
      .jumbotron-role {
        @include animation-name(roleBounce);
        @include animation-duration(.7s);
      }
    }
  }
}


@keyframes roleBounce {
  0% {
    @include scaleY(0);
  }
  48% {
    @include scaleY(1.15);
  }
  62% {
    @include scaleY(0.87);
  }
  75% {
    @include scaleY(1.08);
  }
  85% {
    @include scaleY(0.95);
  }
  93% {
    @include scaleY(1.04);
  }
  100% {
    @include scaleY(1);
  }
}
