footer.footer {
  z-index: 200;
  background: #333;
  padding: 10px 0;
  position: relative;
  bottom: 0;
  width: 100%;
  border-top: 0; 

  &:before {
    background-image: url(images/Cityscape_Min.svg);
    background-size: 75%;
    background-position: bottom center;
    background-color: transparent;
    background-repeat: no-repeat;
    height: 14vw;
    bottom: 35px;
    position: absolute;
    width: 100%;
    content: "";
    padding: 0;
    pointer-events: none;
    left: 0;

    @media (min-width: $screen-xs-min) {background-size: 60%; } //480px & greater
    @media (min-width: $screen-sm-min) {background-size: 55%; } //768px & greater
    @media (min-width: $screen-md-min) {background-size: 50%; } //992px & greater
    @media (min-width: $screen-lg-min) {background-size: 40%; } //1200px & greater

  }
  p a, p {
    color: #999;
    font-size: 11px;
    text-align: center;
  }
}
