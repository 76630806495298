.front {
  .front-hero {
    height: 100%;
    width: 100%;

    .row {
      height: 100%;

      h1 {
        font-size: 26vmin;
        line-height: .77;
        text-transform: uppercase;
        margin-top: 12vh;
        font-weight: 900;

          .br {
            // @media (min-width: $screen-sm-min) {
              &:before {
                content: "\A";
                white-space: pre;
              }

            // }
          }
      }

      .home-left, .home-right {
        padding: 0;
      }

      .home-left{
        background-image: url('http://www.mattgastgeb.com/sites/default/files/ampersand_background.png');
        height: 100%;

        h1 {
          color: $brand-primary;
          transition: .25s ease-in-out;
          position: relative;
          left: -2vmin;
        }
      }

      .home-right {
        background-color: $brand-primary;
        height: 100%;
        transition: .25s ease-in-out;

        h1 {
          color: #fff;
          text-align: right;
        }
      }
    }

    &.green {
      .home-left {
        h1 {
          color: $brand-tertiary;
        }
      }
      .home-right {
        background-color: $brand-tertiary;
      }
    }

    &.blue {
      .home-left {
        h1 {
          color: $brand-fourth;
        }
      }

      .home-right {
        background-color: $brand-fourth;
      }
    }
  }
}

@keyframes fadeLoop {
  0% {
    opacity:1;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
