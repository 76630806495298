.works-new, .contact-page {
  .top-row {
    transition: .25s ease-in-out;

    .inside {
      padding: 40px 0 60px;

      h1,h2,h3 {
        line-height: 1.1;
        color: #fff;
        @media (min-width: $screen-sm-min) {line-height: .9 } //768px & greater
      }
      h1 {
        font-size: 11vw;

        @media (min-width: $screen-sm-min) {font-size: 84px } //768px & greater
        @media (min-width: $screen-lg-min) {font-size: 98px} //1200px & greater
      }
      h2 {
        font-size: 6vw;

        @media (min-width: $screen-sm-min) {font-size: 44px } //768px & greater
        @media (min-width: $screen-lg-min) {font-size: 58px} //1200px & greater
      }
      h3 {
        font-size: 4vw;

        @media (min-width: $screen-sm-min) {font-size: 36px } //768px & greater
        @media (min-width: $screen-lg-min) {font-size: 44px} //1200px & greater
      }
    }
  }

  .top-work {
    // margin-bottom: $grid-gutter-width;

    .top-work-row {
      .field-content {
        float: left;
        @media (min-width: $screen-sm-min) {display: flex;}

        .new-works-txt {
          background-color: transparent;
          transition: .35s ease-in-out;
          padding: ($grid-gutter-width / 2) !important;
          display: flex;
          flex-direction: column;
          justify-content: center;

          h3 {
            margin-top: 0;
            a {
              color: #333;
              transition: .35s ease-in-out;
              @media (min-width: $screen-md-min) {font-size: 36px}
              @media (min-width: $screen-lg-min) {font-size: 42px}
            }
          }

          p {
            @media (min-width: $screen-md-min) {font-size: 16px}
            @media (min-width: $screen-lg-min) {font-size: 18px}
          }

          .red-line {
          width: 33.333333%;
          height: 5px;
          display: block;
          margin: 20px 0;
          background-color: $brand-primary;

            &:after {
              content: "";
              display: block;
              position: relative;
              display: block;
              top: 0;
              background-color: #fff;
              width: 0%;
              height: 5px;
              transition: .35s ease-in-out;
            }
          }
        }
        .new-works-img {
          overflow: hidden;

          &:after {
            content: "";
            display: block;
            position: absolute;
            top:0;
            width: 100%;
            height: 100%;
            background: #fff;
            pointer-events: none;
            opacity: 0;
            transition: 2.5s ease-in-out;
          }

          img {
              transform: scale(1) rotate(0);
              transition: 2.5s ease-in-out;
              transform-origin: center center;
          }
        }
      }


      &.views-row-even {
        .new-works-img {
          @media (min-width: $screen-sm-min) {left: 33.33333%;}
        }

        .new-works-txt {
          @media (min-width: $screen-sm-min) {right: 66.66667%;text-align: right;}
        }

        .red-line {
          @media (min-width: $screen-sm-min) {position: relative;left: 66.666667%;}
        }
      }

      &:hover, &hover_effect {
        .field-content {
          .new-works-txt {
            background-color: $brand-primary;

            h3 {
              a {
                color: #fff;
              }
            }

            p {
              color: #fff;
            }

            .red-line {
              &:after {
                width: 100%;
              }
            }
          }

          .new-works-img {
            &:after {
              opacity: .7;
            }

            img {
                transform: scale(1.07) rotate(2deg);
            }
          }
        }
      }
    }
  }
}
