body {
font-weight: 300;
}
h1 {
  font-weight: 700;

  &.page-header {
    border-bottom: 0;
    margin-top: 30px;
    font-size: 56px;
  }
}

.front {
  .jumbotron {
    h1,h2,h3 {
      font-size: 64px;
      color: $brand-primary;
    }
    h2, h3 {
      font-weight: 800;
    }
    h3 {
      font-size: 45px;
    }
    h2 {
      font-size: 50px;
    }
    .jumbotron-role {

    }

    // .article:before {
    //   content: "a lot of other things";
    //   color: $gray-lighter;
    //   position: relative;
    //   bottom: 0;
    //   left: 0;
    // }
  }
}
html, body {height: 100%;}

h1

h2 {
  &.pane-title {
    text-align: center;
  }
}

.page-works h1.page-header {margin-bottom: 0; padding-bottom: 0;}

p {
  font-size: 16px;

  &.big {
      font-size: 140%;
      font-weight: 500;
      color: #444;

      @media (min-width: $screen-sm-min) {font-size: 180%} //768px & greater
  }
}

.breadcrumb {
  letter-spacing: 0.2px;
  font-size: 86%;
  background-color: transparent;
  padding: 8px ($grid-gutter-width / 2);

  li a {
    color: #ccc;
    font-weight: 400;
    transition: .5s;

    &:hover {
      color: $brand-primary
    }
  }

  >.active {
    color: #ccc;
  }
}
