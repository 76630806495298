// mattgastgeb-navbar scss
body {
  &.admin-menu {

    .navbar-fixed-top.home-position {
      top: inherit;
    }
  }

  &.navbar-is-fixed-top {
    padding-top: 66px;

    &.front {
      padding-top: 0;
    }
  }
}

body.front.admin-menu.navbar-is-fixed-top {
  padding-top: 0 !important;
}

.navbar.navbar-default {
  margin-bottom: 0;
  opacity: 1;
  transition: opacity .5s;
  border: 0;
  background-color: #fff;
  box-shadow: 0px 0px 16px rgba(145,145,145,0);
  transition: .25s ease-in-out;

  &.shadow {
    box-shadow: 0px 0px 16px rgba(145,145,145,0.2);
  }

  .navbar-toggle {
    border: 0;
    padding: 5px 0;
    margin-top: 18px;

    &:hover, &:focus {
      background-color: transparent;
    }

    .icon-bar {
      background-color: $brand-primary;
      border-radius: 0;
      opacity: 1;
      transform-origin: center center;
      transform: translateX(0) translateY(0) rotate(0);
      transition: .5s;
      width: 23px;
      height: 4px;
    }

    &.active {
      .icon-bar:nth-child(2) {
          transform: translateY(8px) rotate(135deg);
      }

      .icon-bar:nth-child(3) {
          opacity: 0;
      }

      .icon-bar:nth-child(4) {
          transform: translateY(-8px) rotate(-135deg);
      }
    }
  }

  &.nav-down {
    transform: translateY(0);
    transition: .25s ease-in-out;
  }

  &.nav-up {
    transform: translateY(-80px);

    .front & {
      transform: translateY(0px);
    }
  }

  .logo-wrapper {
    .logo {
      width: 95.5px;
      overflow: hidden;
      height: 50px;

      img.black-logo {
        position: relative;
        top: 0;
        left: 0;
        opacity: 1;
        width: 95.5px;
        height: auto;
        // transition: opacity .5s;
      }

      // img.white-logo {
      //   position: absolute;
      //   top: 0;
      //   left: 0;
      //   height: 50px;
      //   width:auto;
      //   opacity: 0;
      //   transition: opacity .5s;
      // }
      //
      &.over img.black-logo {
        animation: logoImplodeIn  .5s steps(20) 1 forwards;
      }

      &.out img.black-logo {
        animation: logoImplodeOut  .5s steps(20) 1 backwards;
      }
    }
  }

  .navbar-nav {
    > li {
      > a {
        padding: 25px 25px 20px 25px;
        width: 100%;
        text-align: center;
        font-family: $headings-font-family;
        color: $brand-primary;
        transition: .4s;
        letter-spacing: 0.3px;
        @include hvr-overline-reveal;

        &:hover {
          color: #fff;
          background-color: transparent;
        }
      }

      &.active {
        > a {
          color: darken($brand-primary, 10%);
        }
      }
    }
    @media (min-width: $grid-float-breakpoint) {
      float: right;
    }
  }

  @media (min-width: $screen-sm-min) {
    &.color {
      .navbar-nav {
        > li, > .active {
          > a {
            color: #fff !important;
          }
        }
      }
    }
    &.color {
      .logo-wrapper {
        .logo {
          img {
            top: -1000px;
          }
          // img.black-logo {
          //   opacity: 0;
          // }
          //
          // img.white-logo {
          //   opacity: 1;
          // }
        }
      }
    }

    // &.logo-hover-start {
    //   transition: .5s;
    // }

    &.red {
      background-color: $brand-primary;
    }

    &.green {
      background-color: $brand-tertiary;
    }

    &.blue {
      background-color: $brand-fourth;
    }
  }

  .logo {
    // opacity: 1;
    // transform: scale(1);
    transition: .4s;
    margin-left: 15px;
    padding-left: 0;
    position: relative;

    @media (min-width: $screen-sm-min) {margin-left: 0; }

  }
  &.home-position {
    position: absolute;
    width: 100%;
    bottom: 0;
    top: inherit;
    // opacity: .8;

    .logo {
      // opacity: 0;
      // transform: scale(0);
    }
  }
}

@keyframes logoImplodeIn {
  from {
    top: 0px;
  }
  to {
    top: -1000px;
  }
}

@keyframes logoImplodeOut {
  from {
    top: -1000px;
  }
  to {
    top: 0px;
  }
}
