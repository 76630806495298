body {
font-weight: 300;
}

.main-container section.col-sm-12 {
    padding: 0;
}

.main-container {
  padding-bottom: 70px;
}

.full-width-cap {
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
}

.stripe-bg {
  background-image: url(images/stripe.png)
}

.grain-green {
  background-color: $brand-tertiary;
  background-image: url(images/grain.png);
}

.grain-red {
  background-color: $brand-primary;
  background-image: url(images/grain.png);
}

.grain-blue {
  background-color: $brand-fourth;
  background-image: url(images/grain.png);
}

.color-panel {
  transition: .25s ease-in-out;
}

.grain-green, .grain-red, .grain-blue {
  h1, h2 {
    color: #fff;
  }
}

.panels-flexible-row.container {
    margin-left: auto !important;
    margin-right: auto !important;
}

.container-fluid, .container {
  .no-gutter [class*='col-'] {
      padding-right:0;
      padding-left:0;
  }

  &.no-gutter {
    padding-right:0;
    padding-left:0;
  }

  .row {
    &.no-gutter {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.gutter-top {
  margin-top: $grid-gutter-width;
}

.gutter-bottom {
  margin-bottom: $grid-gutter-width;
}

.pane-node-field-extra-project-element {
  overflow: hidden;
}
