.webform-client-form {
  margin-left: ceil(($grid-gutter-width / -2));
  margin-right: floor(($grid-gutter-width / -2));

  .captcha {
    float: left;
    padding: ($grid-gutter-width / 2);
  }

  .form-actions {
    padding-left: ($grid-gutter-width / 2);
    padding-right: ($grid-gutter-width / 2);
  }

  .form-actions {
    padding-top: ($grid-gutter-width / 2);
  }

  .form-control {
    margin-top: ($grid-gutter-width / 2);
  }

  .col-sm-6.form-item {
    margin-bottom: $grid-gutter-width;

    @media (min-width: 768px) {
      margin-bottom: ($grid-gutter-width / 2);
    }

    &:last-child {
      margin-bottom: ($grid-gutter-width / 2);
    }
  }

  .form-actions {
    padding-bottom: $grid-gutter-width;
  }
}

.form-control {
  box-shadow: none;
  padding: 25px 12px;
  border: 0;
  transition: .5s ease-in-out;

  &:focus {
    border-color: $brand-fourth;
    outline: 0;
    box-shadow: 0 7px 15px -5px rgba(0,0,0,0.4);
  }
}
