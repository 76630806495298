// mattgastgeb.com btn

.btn-web-link {
  width: 100%;
  text-transform: uppercase;
  border: 0;
  padding: 14px;

  @include hvr-sweep-to-right;
}

.btn-download {
  color: #333;
  border: 0;
  @include hvr-sweep-to-bottom;

  &:after {
    font-family: "fontawesome";
    content: "\f019";
    padding-left: 5px;
  }
}

.btn-big-boy {
  font-family: $headings-font-family;
  font-size: 20px;
}
