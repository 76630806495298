.jumbotron
{background-image: url(images/stripe.png)!important}

.front-hero {
  // background-image: linear-gradient(rgba(0,0,0,0.2),rgba(0,0,0,0.2)), url(/sites/default/files/videoreplacement.jpg);
  // background-size: cover;
  // height: 100%;
  // width: 100%;

  // h1 {
  //   color: #fff;
  // }
  //
  // .front-hero-info {
  //   padding-top: 15vh;
  //   opacity: 0.8;
  // }

  .logo-main {
    max-width: 100%;

    @media (min-width: $screen-sm-min) {max-width: 500px; }  //768px & greater

  }
}
