.home-grid {
  .no-gutter {
    .panels-flexible-row {
      padding: 0;
      margin: 0;
    }
  }
}
.work-hover {
  overflow: hidden;

  &.single-image {
    a {
      cursor: zoom-in;
    }
  }

  .work-hover-caption {
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
      background-color: rgba(255, 100, 71, 0);
      transition: 1s ease-in-out;
      display: flex;
      justify-content: center;
      flex-direction: column;
      overflow: hidden;
      pointer-events: none;

      a {
        color: #fff;
        font-family: 'montserrat', sans-serif;
        font-size: 150%;
        padding: auto 0;
        display: block;
        padding: 10px;
        line-height: 100%;

        @media (min-width: $screen-sm-min) {font-size: 210%}
        @media (min-width: $screen-md-min) {font-size: 300%}

        &:after, &:before {
          content: "";
          width: 100%;
          display: block;
          position: relative;
        }

        &:after {
            border-bottom: 4px #fff solid;
            top: 10px;

            @media (min-width: $screen-sm-min) {border-bottom: 6px #fff solid;}
            @media (min-width: $screen-md-min) {border-bottom: 7px #fff solid;}
        }

        &:before {
            border-top: 4px #fff solid;
            top: -10px;

            @media (min-width: $screen-sm-min) {border-top: 6px #fff solid;}
            @media (min-width: $screen-md-min) {border-top: 7px #fff solid;}
        }
      }
  }

  img {
      transform: scale(1) rotate(0);
      transition: 2.5s ease-in-out;
      transform-origin: center center;
    }

  &:hover, &:focus, &.hover_effect {
    .work-hover-caption {
       background-color: rgba(255, 100, 71, .8);
     }

     img {
         transform: scale(1.1) rotate(5deg);
     }
  }
}

.cssanimations {
  .work-hover {
    .work-hover-caption {
      a {
        opacity: 0;
      }
    }

    &:hover, &:focus, &.hover_effect {
      .work-hover-caption {
        a {
          transform-origin: left center;
         animation: laserInTitle 1s cubic-bezier(.55,.085,0,.99) forwards;

         &:before {
           transform-origin: left center;
           animation: laserInLine1 1s cubic-bezier(.55,.085,0,.99) forwards;
         }

         &:after {
           transform-origin: left center;
           animation: laserInLine2 1s cubic-bezier(.55,.085,0,.99) forwards;
         }
       }
      }
    }
  }
}

@keyframes laserInTitle {
  0% {
    transform: scaleX(0) scaleY(0.01);
  }

  50% {
    transform: scaleX(1) scaleY(0.01);
  }


  100% {
    transform: scaleX(1) scaleY(1);
    opacity: 1;
  }
}

@keyframes laserInLine1 {
  0% {
    transform: scaleX(0) scaleY(20) translateY(100%)
  }

  50% {
    transform: scaleX(1) scaleY(20) translateY(100%)
  }

    55% {
    transform: scaleY(5)
  }

  100% {
    transform: scaleX(1) translateY(0)
  }
}

@keyframes laserInLine2 {
  0% {
    transform: scaleX(0) scaleY(20) translateY(-100%)
  }

  50% {
    transform: scaleX(1) scaleY(20) translateY(-100%)
  }

  55% {
    transform: scaleY(5)
  }

  100% {
    transform: scaleX(1) translateY(0)
  }
}

.no-cssanimations {
  .work-hover {
    .work-hover-caption {
      a {
        transition: .25s ease-out;
        transition-delay: .25s;
        transform: scaleY(0);

        &:after, &:before {
          transition: .25s ease-out;
          transition-delay: .25s;
        }

        &:after {
            transform: translateY(30px);
        }

        &:before {
            transform: translateY(-30px);
        }
      }
    }

    &:hover, &:focus, &.hover_effect {
      .work-hover-caption {
         a {
           transform: scaleY(1);

           &:before, &:after {
             transform: translateY(0);
           }
         }
      }
    }
  }
}

.all-works {
  .workgrid {
    .view-header {
      text-align: center;
      margin-bottom: 30px;

      h3 {
        display: inline-block;
        color: #fff;
        background: rgba(0,0,0,.7);
        padding: 4px;
        font-weight: 700;
      }
    }

    .view-content {
      margin-left: ceil(($grid-gutter-width / -2));
      margin-right: floor(($grid-gutter-width / -2));
      min-height: 500px;
    }

    .views-row {
      margin-bottom: $grid-gutter-width;

      .work-hover
      {
        .work-hover-caption {
          width: calc(100% - #{$grid-gutter-width});
        }
      }
    }
  }
}

.panel-project-page {
  .views-field-field-photo-gallery-title {
    text-align: center;
    margin: 20px 0;
  }

  .views-field-field-photo-gallery-description {
    margin-bottom: $grid-gutter-width;
  }

  .views-field-field-photo-gallery-1, .views-field-field-photo-gallery-2 {
    .project-gallery-row {
        overflow: hidden;

        .mfp-gallery-image {

           @media (min-width: $screen-sm-min) {margin-left: ceil(($grid-gutter-width / -2));
           margin-right: floor(($grid-gutter-width / -2));}

       }
    }

    .mfp-custom-thumbnail.mfp-item {
       width: 50%;
        float: left;
        padding-left: ($grid-gutter-width / 2);
        padding-right: ($grid-gutter-width / 2);
        padding-bottom: $grid-gutter-width;
        min-height: 1px;
        opacity: 1;
        transition: opacity .75s;

        &:hover, &:focus {
          opacity: .4
        }
    }

  }

  .views-field-field-photo-gallery-1 .mfp-custom-thumbnail.mfp-item {
    @media (min-width: $screen-sm-min) {width: 25%;} //768px & greater
  }
  .views-field-field-photo-gallery-2 .mfp-custom-thumbnail.mfp-item {
    @media (min-width: $screen-sm-min) {width: 33.3333%;} //768px & greater
  }
}

.workgrid {
  &.related-projects {
     .view-content {
       margin-left: ceil(($grid-gutter-width / -2));
       margin-right: floor(($grid-gutter-width / -2));

       .views-row {
         margin-bottom: 30px;

         .work-hover {
           .work-hover-caption {
             width: calc(100% - #{$grid-gutter-width});

             a {
               font-size: 150%;
             }
           }
         }
       }
     }
  }
}

.icon-Set {
    text-align: center
}

.icon-Set img {
    display: inline-block;
    max-width: 50%;
    height: auto;
    padding-bottom: $grid-gutter-width;
    padding-left: ($grid-gutter-width / 2);
    padding-right: ($grid-gutter-width / 2);

    @media (min-width: $screen-sm-min) {max-width: 65%;} //768px & greater
    @media (min-width: $screen-md-min) {max-width: 75%;} //992px & greater
}

.front {
  .workgrid {
    max-width: 1600px;

    @media (min-width: 1600px) {
      margin-left: auto;
      margin-right: auto;
    }
  }
}
